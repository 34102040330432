import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./landing-page/landing-page.module').then(
            (m) => m.LandingPageModule
          ),
      }
    ],
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy-page/privacy-policy-page.module').then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'user/delete',
    loadChildren: () =>
      import('./delete-account-page/delete-account-page.module').then(
        (m) => m.DeleteAccountPageModule
      ),
  },
  {
    path: 'recharge-plans',
    loadChildren: () =>
      import('./recharge-plans-page/recharge-plans.module').then(
        (m) => m.RechargePlansModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
